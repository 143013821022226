<template>
  <section id="not-found">
    <!-- <div id="title"></div> -->
    <div class="circles">
      <p>404<br>
        <small>PAGE NOT FOUND</small>
      </p>
      <span class="circle big"></span>
      <span class="circle med"></span>
      <span class="circle small"></span>
    </div>
    <b-button
      variant="outline-primary"
      style="z-index:99;"
      @click="handleBackToHome">BACK TO HOME</b-button>
  </section>
</template>

<script>
import { goPage } from '@/utils/route'

export default {
  methods: {
    handleBackToHome () {
      goPage({
        name: 'Root'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#not-found {
  width:100%;
  height:100vh;
  // background:#48A9E6;
  font-family: 'Raleway', sans-serif;
  font-weight:300;
  margin:0;
  padding:0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#title {
  text-align:center;
  font-size:40px;
  margin-top:40px;
  margin-bottom:-40px;
  position:relative;
  color:#fff;
}

.circles:after {
  content:'';
  display:inline-block;
  width:100%;
  height:100px;
  background:#fff;
  position:absolute;
  top:-50px;
  left:0;
  transform:skewY(-4deg);
  -webkit-transform:skewY(-4deg);
}

.circles {
  background:#fff;
  text-align: center;
  position: relative;
  margin-top:-60px;
  // box-shadow:inset -1px -4px 4px rgba(0,0,0,0.2);
}

.circles p {
  font-size: 240px;
  color: #fff;
  padding-top: 60px;
  position: relative;
  z-index: 9;
  // line-height: 100%;
}

.circles p small {
  font-size: 40px;
  // line-height: 100%;
  vertical-align: top;
}

.circles .circle.small {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background: #48A9E6;
  position: absolute;
  z-index: 1;
  top: 80px;
  left: 50%;
  animation: 7s smallmove infinite cubic-bezier(1,.22,.71,.98);
  -webkit-animation: 7s smallmove infinite cubic-bezier(1,.22,.71,.98);
  animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
}

.circles .circle.med {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: #48A9E6;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 10%;
  animation: 7s medmove infinite cubic-bezier(.32,.04,.15,.75);
  -webkit-animation: 7s medmove infinite cubic-bezier(.32,.04,.15,.75);
  animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
}

.circles .circle.big {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: #48A9E6;
  position: absolute;
  z-index: 1;
  top: 200px;
  right: 0;
  animation: 8s bigmove infinite;
  -webkit-animation: 8s bigmove infinite;
  animation-delay: 3s;
  -webkit-animation-delay: 1s;
}

@-webkit-keyframes smallmove {
  0% { top: 10px; left: 45%; opacity: 1; }
  25% { top: 300px; left: 40%; opacity:0.7; }
  50% { top: 240px; left: 55%; opacity:0.4; }
  75% { top: 100px; left: 40%;  opacity:0.6; }
  100% { top: 10px; left: 45%; opacity: 1; }
}
@keyframes smallmove {
  0% { top: 10px; left: 45%; opacity: 1; }
  25% { top: 300px; left: 40%; opacity:0.7; }
  50% { top: 240px; left: 55%; opacity:0.4; }
  75% { top: 100px; left: 40%;  opacity:0.6; }
  100% { top: 10px; left: 45%; opacity: 1; }
}

@-webkit-keyframes medmove {
  0% { top: 0px; left: 20%; opacity: 1; }
  25% { top: 300px; left: 80%; opacity:0.7; }
  50% { top: 240px; left: 55%; opacity:0.4; }
  75% { top: 100px; left: 40%;  opacity:0.6; }
  100% { top: 0px; left: 20%; opacity: 1; }
}

@keyframes medmove {
  0% { top: 0px; left: 20%; opacity: 1; }
  25% { top: 300px; left: 80%; opacity:0.7; }
  50% { top: 240px; left: 55%; opacity:0.4; }
  75% { top: 100px; left: 40%;  opacity:0.6; }
  100% { top: 0px; left: 20%; opacity: 1; }
}

@-webkit-keyframes bigmove {
  0% { top: 0px; right: 4%; opacity: 0.5; }
  25% { top: 100px; right: 40%; opacity:0.4; }
  50% { top: 240px; right: 45%; opacity:0.8; }
  75% { top: 100px; right: 35%;  opacity:0.6; }
  100% { top: 0px; right: 4%; opacity: 0.5; }
}
@keyframes bigmove {
  0% { top: 0px; right: 4%; opacity: 0.5; }
  25% { top: 100px; right: 40%; opacity:0.4; }
  50% { top: 240px; right: 45%; opacity:0.8; }
  75% { top: 100px; right: 35%;  opacity:0.6; }
  100% { top: 0px; right: 4%; opacity: 0.5; }
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/raleway/v18/1Ptug8zYS_SKggPNyCAIT4ttDfCmxA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/raleway/v18/1Ptug8zYS_SKggPNyCkIT4ttDfCmxA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/raleway/v18/1Ptug8zYS_SKggPNyCIIT4ttDfCmxA.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/raleway/v18/1Ptug8zYS_SKggPNyCMIT4ttDfCmxA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/raleway/v18/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/raleway/v18/1Ptug8zYS_SKggPNyCAIT4ttDfCmxA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/raleway/v18/1Ptug8zYS_SKggPNyCkIT4ttDfCmxA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/raleway/v18/1Ptug8zYS_SKggPNyCIIT4ttDfCmxA.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/raleway/v18/1Ptug8zYS_SKggPNyCMIT4ttDfCmxA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/raleway/v18/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
</style>
